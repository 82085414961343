<template>
  <div class="about">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="about" />
    <div class="about-container">
      <div class="top-container">
      <div class="left">
        <div class="img-container">
          <img src="../../public/img/about/about_img.jpg" alt="">
        </div>
      </div>
      <div class="right">
        <div class="content-container">
          <h3>公司簡介</h3>
          <p>EXUSTAR自2002年以來，專注於自行車、室內健身及機車人身之相關部品的研發，以提供使用者更便利、穿戴更舒適、提供更高效的產品使用經驗為宗旨，不斷創新研發新技術和開發新產品，將產品的質量和安全放在首位。經營EXUSTAR自有品牌，並銷售至全球多達40 個國家，同時也與客戶攜手進行ODM 高階設計研發，成為客戶在國際市場上成功的重要推手。</p>
          <p>Exustar 每年贊助國內外車隊，提供年輕車手最優質產品， 2021年贊助這些優秀年輕的自行車車隊，我們期待在2021有很好的表現!<br>
            荷蘭- ABLOC CT Team 公路車車隊<br>
            義大利-Trinx Factory Team山地車車隊<br>
            義大利- Four Es Project 山地車車隊<br>
            法國- Velcan MTB Pro team山地車車隊<br>
            南美- Start Cycling team 公路車車隊<br>
            義大利-Mattia Fulan BMX競速選手<br>
          </p>
        </div>
      </div>
      </div>
      <h3 class="timeline-title">EXUSTAR發展</h3>
      <div class="icon">
        <v-icon>
          mdi-menu-down
        </v-icon>
      </div>
      <template>
        <div class="timeline-container">
          <v-timeline
            :reverse="false"
            :dense="$vuetify.breakpoint.smAndDown"
          >
            <v-timeline-item
              v-for="item in main_data"
              :key="item.index"
              color="grey darken-3"
              small
            >
              <v-card class="elevation-2"
              color="grey lighten-3"
              elevation=0>
                <v-card-title class="text-h5">
                  {{ item.title }}
                </v-card-title>
                <v-card-text
                class="black--text" v-html="item.content">
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from "../components/pagenationtop/index.vue";
export default {

    data(){
        return{
            pagenation_title:[
                '關於EXUSTAR'
            ],
            main_data:[
              {
                title:'2002年',
                content:'Exustar品牌創立。'
              },
              {
                title:'2003年',
                content:'發展碳纖維產品。'
              },
              {
                title:'2005年',
                content:'開發Exustar卡踏系統（EPS）。'
              },
              {
                title:'2006年',
                content:'Exustar 以重機車靴產品線進入摩托車市場。'
              },
              {
                title:'2007年',
                content:'在Innobike設計比賽中獲得踏板類最高榮譽。投入生產鈦軸心踏板。'
              },
              {
                title:'2009年',
                content:'踏板輕量化的突破：腳踏重量僅176克/組（E-PR200CKTI）。'
              },
              {
                title:'2010年',
                content:'Exustar贊助車手蕭美玉在亞運會上贏得女子公路賽的金牌。'
              },
              {
                title:'2011年',
                content:'Exustar與Garmin合作，開發Garmin Vector功率踏板。'
              },
              {
                title:'2012年',
                content:'針對公路腳踏提供不同程度的脫出張力選擇：E-PR1（標準張力）、E-PR2（高張力）、E-PR3（超高張力）。'
              },
              {
                title:'2013年',
                content:'Exustar進入美國市場。在台灣新設立最先進的踏板製造廠。'
              },
              {
                title:'2014年',
                content:'Exustar推出一系列專為室內健身飛輪課程設計，特別堅固耐用的室內健身踏板產品。'
              },
              {
                title:'2015年',
                content:'因應業務之成長，遷入更大的廠房，進行CNC加工設備升級，使公司能夠執行更先進的精密製程。'
              },
              {
                title:'2016年',
                content:'憑藉市面上最堅固、最耐用和最多功能的室內踏板產品線，以及專為室內健身設計的室內鞋款，將室內健身運動提升到一個全新的水平。'
              },
              {
                title:'2017年',
                content:'為了提高生產效率，轉移生產基地。'
              },
              {
                title:'2018年',
                content:'專為室內騎行運動，開發出更耐用的EPX驅動系統。'
              },
              {
                title:'2019年',
                content:'因應發展方針，合併了摩托車和自行車銷售團隊。'
              },
              {
                title:'2020年',
                content:'發表三硬度扣片技術，推出一系列透明本體的公路踏板。'
              },
              {
                title:'2021年',
                content:'Exustar 贊助的義大利山地車車手Eva Lechner 於東京奧運與各國好手同台競技，並推出特仕版腳踏。'
              },
              {
                title:'2022年',
                content:'Exustar 慶祝成立20週年里程碑。'
              },
            ]
        }
    },
    created(){
        this.$store.commit('closeOverlay')
    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
}
</script>

<style>

</style>